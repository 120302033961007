import React from 'react'
import Layout from 'components/Layout'
import Link from 'components/Link'

const ServicePage = ({ location }) => (
  <Layout
    title="All the tools to unlock your data"
    description="Use UseData to connect to any data source (Redshift, BigQuery, MySQL, PostgreSQL, MongoDB and many others), query, visualize and share your data to make your company data driven."
    location={location}
  >
    <section className="section section-product-hero">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center">
            <h1 className="m-b-100 serif">Develop <u>multi-cloud</u> data strategy for your digital transformation</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <h3 className="m-t-0 feature_font">
              <span className="m-r-15 feature_icon"><i className="fa fa-bolt" aria-hidden="true"></i></span> Data Strategy
            </h3>
            <h2 className="m-t-0">
              Gain insights from various massive amounts of data to drive business growth
            </h2>
            <ul className="highlight-ul">
              <li>Making the most value from the wealth of potential data begins with excellence in identifying, capturing, and storing that data</li>
              <li>Refining data by a series of processing activities to translate data insights into business value</li>
              <li>Making insight-driven actions a part of the standard day-to-day work activities within departments, teams and processes to maximize data monetization </li>
            </ul>
            <Link
              data-track
              data-track-location="product-hero"
              track-event="Clicked Check Integrations"
              to="/services/data-science/"
              className="btn btn-secondary"
            >
              Learn more
            </Link>
          </div>

          <div className="col-md-6">
            <div className="browser-container hidden-xs">
              <img
                src="/assets/images/elements/data-science-services.png"
                width="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section bg-soft-yellow">
      <div className="container">
        <div className="row">
          <div className="col-md-6 push-sm-down">
            <div className="dashboard-container hidden-xs">
              <img
                src="/assets/images/elements/mckinsey_cloud.svg"
                width="100%"
              />
            </div>
          </div>

          <div className="col-md-6 text-right">
            <h3 className="m-t-0 feature_font text-right">
              <span className="m-r-15 feature_icon"><i className="fa fa-bar-chart" aria-hidden="true"></i></span> Cloud Adoption
            </h3>
            <h2 className="m-t-0 text-right">Utilize multiple cloud services to make a shift to digital transformation</h2>
            <ul className="highlight-right-ul">
              <li>
                Decrease in <u>time to market</u> for new applications, which in turn can drive down costs and quickly improve product quality
              </li>
              <li>
                Offer manifold advantages over conventional on-premise systems, from lower operating costs to better compatibility with digital enterprises
              </li>
              <li>
                Ease the transition with hybrid-cloud configurations that progressively combine private and public cloud features
              </li>
            </ul>
            <Link
              data-track
              data-track-location="product-hero"
              track-event="Clicked Check Integrations"
              to="/services/cloud-computing/"
              className="btn btn-secondary"
            >
              Learn more
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ServicePage
